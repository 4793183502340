import React, { useEffect, useRef, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { BsChatRightTextFill, BsEmojiSmile } from "react-icons/bs";
import { Box, Button, IconButton, InputAdornment, TextField } from "@mui/material";
import "./chat.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import axios from "axios";
// import { ActiveChat } from "./ActiveChat";
import { ActiveChat } from "./activeChat";
import { localstore } from "../localstore/localstore";
import { CustomTextField } from "../atoms/TextField";
import { createInteractionData } from "../atoms/getLocations";
import { closeWebSocket, initializeWebSocket } from "../doctors/webSocketManager";

const SendMessages = ({ sendJsonMessage, setSnackbarOpen, name, phone, ptId, messageHistory, handleChangeName, handleChangePhone, handleSubmit, typing, adminSeen }) => {
    return (
        <Box>
            {
                ptId ?
                    <div style={{ display: 'flex', flexDirection: 'column', }
                    } >
                        <ActiveChat setSnackbarOpen={setSnackbarOpen} sendJsonMessage={sendJsonMessage} messageHistory={messageHistory} typing={typing} adminSeen={adminSeen} />
                    </div >
                    :
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '50px', gap: '35px', justifyContent: 'center', padding: '10px 20px' }} className="pt-2">

                        <CustomTextField type="text" name="name" placeholder="Name" value={name}
                            backgroundColor="rgb(243, 244, 246)" borderRadius="7px" onChange={handleChangeName}
                        />

                        <TextField
                            fullWidth
                            size="small"
                            type={'text'}
                            value={phone}
                            onChange={handleChangePhone}
                            placeholder="Enter Phone Number"
                            sx={{
                                width: '100%', mt: "5px",
                                ".MuiInputBase-root": {
                                    borderRadius: '7px',
                                    backgroundColor: "rgb(243, 244, 246)"
                                },
                                '& .MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#59D8FA',
                                    },
                                },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        +91
                                    </InputAdornment>
                                )
                            }}
                        />
                        <Button variant="outlined" sx={{ borderRadius: '7px', borderColor: '#E44190', mt: 2, color: '#E44190', textTransform: 'capitalize', '&:hover': { borderColor: '#E44190', color: '#E44190' } }}
                            className=" bg-gray-200 px-3 py-2 text-[#E44190]"
                            onClick={handleSubmit}>
                            Submit
                        </Button>
                    </div>
            }
        </Box>
    )
}

export default function Chat(props) {
    const { userListOpen, messages, adminSeen, setSnackbarOpen, setUserListopen, newmessages, userDetails, phone, handleChangeName, handleChangePhone, name, messageHistory, handleSubmit, sendJsonMessage, typing, ptId, setNewMessages, setContentMessage } = props
    const [show, setShow] = useState(1)

    return (
        <>
            {!userListOpen ? (
                <div
                    // className="message-box"
                    className="mt-1"
                // style={{ padding: "5px 20px" }}
                >
                    <IconButton
                        sx={{
                            backgroundColor: '#E44190',
                            // color: '#FFFFFF',
                            width: 56,
                            height: 56,
                            '&:hover': {
                                backgroundColor: '#E44190',
                            },
                            // mt: 1
                        }}
                        onClick={() => setUserListopen(!userListOpen)}
                    >
                        <BsChatRightTextFill style={{ color: '#FFFFFF' }} className="text-2xl" />
                    </IconButton>
                    {/* <Button
                        variant="contained"
                        size="large"
                        // sx={{ textTransform: "capitalize", fontSize: "16px", backgroundColor: '#E44190' }}
                        onClick={() => setUserListopen(!userListOpen)}
                    > */}
                    {/* </Button> */}
                </div>
            ) :
                (
                    <div
                        className="drop-shadow-lg message-box mt-3"
                        style={{
                            backgroundColor: userDetails?.Participant?.participentId ? '#fdf3f7' : "#fdf3f7",
                            width: "320px",
                            height: `${window.innerHeight - 100}px`,
                            borderTopLeftRadius: '20px', borderTopRightRadius: '20px',

                        }}
                    >
                        <nav className="flex justify-between" style={{ backgroundColor: '#E44190', color: '#FFFFFF', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', padding: '10px 20px' }}>
                            <div >
                                <div className="text-xl">Paramitha Hospitals</div>
                                {typing && <p className="text-white-500 text-xs">Typing...</p>}


                                {/* <div style={{ fontSize: '10px' }}>The WebSocket is currently {connectionStatus}</div> */}
                            </div>
                            <IconButton onClick={() => {
                                setNewMessages([])
                                setContentMessage([])
                                setUserListopen(!userListOpen)
                                setShow(1)
                            }} >
                                <AiOutlineCloseCircle className="text-[#FFFFFF] text-xl" />
                            </IconButton>
                        </nav>
                        {/* {userDetails?.Participant?.participentId ? */}


                        {/* {
                        show === 1 ? (
                            <div style={{ display: 'flex', flexDirection: 'column', }
                            } >
                                <div className="flex-col" style={{ background: "#fdf3f7" }}>
                                    <div
                                        id="scrollableDiv"
                                        style={{ backgroundSize: "cover" }}
                                        className="flex flex-col-reverse relative w-full px-2 overflow-y-auto"
                                    ></div>
                                    {messages.length > 0 ?
                                        <ol type="1">
                                            {messages.map((msg, index) => {

                                                // try {
                                                const parsedMessage = JSON.parse(msg);
                                                console.log(parsedMessage, "parsedmessge")
                                                //     console.log(parsedMessage, `Parsed message ${index}`);
                                                // } catch (error) {
                                                //     console.error(`Error parsing message ${index}:`, error);
                                                // }
                                                return (
                                                    <div style={{ marginTop: '10px' }} >
                                                        <div>
                                                            {parsedMessage?.content}
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                        </ol> : <SendMessages name={name} phone={phone} handleChangeName={handleChangeName} handleChangePhone={handleChangePhone} sendJsonMessage={sendJsonMessage} messageHistory={messageHistory} handleSubmit={handleSubmit} ptId={ptId} />

                                    }
                                    {messages.length > 0 &&
                                        <div className="flex items-center p-2 border-t border-gray-200 bg-white">
                                            <span className="p-2">
                                                <BsEmojiSmile />
                                            </span>
                                            <textarea
                                                // style={{ overflow: 'hidden' }}
                                                // value={message}
                                                // onChange={handleChangeMessage}
                                                // ref={inputReference}
                                                className="w-full border-none p-2 rounded-md resize-none"
                                                rows={1}
                                                placeholder="Type a message"
                                            // onKeyDown={(e) => {
                                            //     if (e.key === "Enter") {
                                            //         handleSubmit()
                                            //     }
                                            // }} 
                                            />
                                            <Button className="text-blue-500 capitalize" style={{ textTransform: 'capitalize' }}>
                                                reply
                                            </Button>
                                        </div>
                                    }
                                </div>
                            </div >
                        ) : show === 2 ? (
                            <> <SendMessages name={name} phone={phone} handleChangeName={handleChangeName} handleChangePhone={handleChangePhone} sendJsonMessage={sendJsonMessage} messageHistory={messageHistory} handleSubmit={handleSubmit} ptId={ptId} /></>
                        ) : null
                    } */}
                        {show === 1 ? (
                            // Chat view with messages
                            <div>

                                {messages?.length > 0 ? (
                                    <div className="flex-grow overflow-y-auto flex flex-col-reverse p-2" style={{ height: `${window.innerHeight - 225}px` }}>
                                        <ol type="1">
                                            {messages?.map((msg, index) => {
                                                const parsedMessage = JSON.parse(msg);
                                                return (
                                                    <div key={index} style={{ marginTop: '10px' }}>
                                                        <div>{parsedMessage?.content}</div>
                                                    </div>
                                                );
                                            })}
                                        </ol>
                                    </div>
                                ) : (
                                    <div>
                                        <SendMessages
                                            name={name}
                                            phone={phone}
                                            handleChangeName={handleChangeName}
                                            handleChangePhone={handleChangePhone}
                                            sendJsonMessage={sendJsonMessage}
                                            messageHistory={messageHistory}
                                            handleSubmit={handleSubmit}
                                            ptId={ptId}
                                            typing={typing}
                                            adminSeen={adminSeen}
                                            setSnackbarOpen={setSnackbarOpen}
                                        />
                                    </div>
                                )}

                                {/* Textarea fixed at the bottom */}
                                {messages?.length > 0 &&

                                    <div className="flex items-center p-2 border-t border-gray-200 bg-white sticky bottom-0">
                                        <span className="p-2">
                                            <BsEmojiSmile />
                                        </span>
                                        <textarea
                                            className="w-full border-none p-2 rounded-md resize-none"
                                            rows={1}
                                            placeholder="Type a message"
                                        />
                                        <Button className="text-blue-500 capitalize" style={{ textTransform: 'capitalize' }} onClick={() => setShow(2)}>
                                            Reply
                                        </Button>
                                    </div>
                                }
                            </div>
                        ) : show === 2 ? (
                            // Alternative view (for example, a "Send Messages" component or a placeholder)
                            <SendMessages
                                name={name}
                                phone={phone}
                                handleChangeName={handleChangeName}
                                handleChangePhone={handleChangePhone}
                                sendJsonMessage={sendJsonMessage}
                                messageHistory={messageHistory}
                                handleSubmit={handleSubmit}
                                ptId={ptId}
                                typing={typing}
                                adminSeen={adminSeen}
                                setSnackbarOpen={setSnackbarOpen}
                            />
                        ) : null}


                        {/* <ol type="1">
                        {messages.length > 0 ? messages.map((msg, index) => {

                            // try {
                            const parsedMessage = JSON.parse(msg);
                            console.log(parsedMessage, "parsedmessge")
                            //     console.log(parsedMessage, `Parsed message ${index}`);
                            // } catch (error) {
                            //     console.error(`Error parsing message ${index}:`, error);
                            // }
                            return (
                                <div style={{ marginTop: '10px' }} >
                                    {parsedMessage?.content}
                                    <div onClick={() => setReply(true)}>reply?</div>
                                </div>
                            )
                        }) : <SendMessages />
                        }

                    </ol>
                    {reply && <SendMessages />} */}
                    </div >
                )
            }
        </>
    );
}
