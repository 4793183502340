import { getLoc } from "../atoms/getLocations";

// webSocketManager.js
let webSocketInstance = null;


export const initializeWebSocket = async (interactionData, onMessage) => {
    try {
        // Fetch location data using getLoc()
        const result = await getLoc();

        // Ensure interactionData has a location key set to result
        const updatedInteractionData = {
            ...interactionData,
            location: result, // Assign the fetched location to the interaction data
        };

        // console.log(updatedInteractionData, "Updated Interaction Data with Location");

        // Initialize WebSocket connection if it's not already created
        if (!webSocketInstance) {
            const socketUrl = `wss://${process.env.REACT_APP_WEBSOCKET_URL}socket/interactions?visitorId=${updatedInteractionData?.visitorId}&location=${updatedInteractionData?.location}`;
            webSocketInstance = new WebSocket(socketUrl);

            webSocketInstance.onopen = () => {
                console.log("WebSocket connection established");
                // Send the interaction data with the updated location
                webSocketInstance?.send(JSON.stringify(updatedInteractionData));
            };

            webSocketInstance.onmessage = (event) => {
                console.log("Message received from server:", event.data);
                if (onMessage) {
                    onMessage(event.data);
                }
            };

            webSocketInstance.onclose = (event) => {
                console.log(`Connection closed, reason=${event.reason}`);
                webSocketInstance = null;
            };

            webSocketInstance.onerror = (error) => {
                console.error("WebSocket error:", error);
            };
        }

        return webSocketInstance;
    } catch (error) {
        console.error("Error initializing WebSocket:", error);
    }
};

export const sendInteractionData = async (interactionData) => {
    try {
        // Fetch location data using getLoc()
        const result = await getLoc();

        // Ensure interactionData has a location key set to result
        const updatedInteractionData = {
            ...interactionData,
            location: result, // Add or overwrite the location key with fetched result
        };

        console.log(updatedInteractionData, "Sending Interaction Data with Location");

        if (webSocketInstance && webSocketInstance.readyState === WebSocket.OPEN) {
            // Send the updated interaction data
            webSocketInstance?.send(JSON.stringify(updatedInteractionData));
        } else {
            console.error("WebSocket is not open. Cannot send interaction data.");
        }
    } catch (error) {
        console.error("Error sending interaction data:", error);
    }
};

export const closeWebSocket = () => {
    if (webSocketInstance) {
        webSocketInstance.close();
        webSocketInstance = null;
    }
};
