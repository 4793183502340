import React, { useEffect, useState } from "react";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import LikedIcon from "../../../assets/images/liked-icon.svg";
import ShareImg from "../../../assets/images/share-icon.svg";
import CommentIcon from "../../../assets/images/comment-icon.svg";
import likeIcon from "../../../assets/images/like-icon.svg";
import { localstore } from "../../localstore/localstore";
import { getMetaData } from "../../atoms/getLocations";
import axios from "axios";
import URL from "../../../services/api_url";
import { getpostbyid } from "../../../redux/actions/loginActions";
import { useDispatch, useSelector } from "react-redux";

function HealthcomActions({ item, setOpen, handleNavigatePost }) {
    const [like, setLike] = useState(false);
    // console.log(item, "1234567890")
    const usertoken = localstore.getToken()
    const dispatch = useDispatch()
    const patientName = localstore.getPatientName()
    const [post, setPost] = useState(item)
    const [firstName, lastName] = patientName?.split(" ") || '';
    const postdata = useSelector((state) => state.login.postdata)

    useEffect(() => {
        if (post?.id === postdata?.id) {
            setPost(postdata)
        }
    }, [post, postdata])
    useEffect(() => {
        if (usertoken) {
            dispatch(getpostbyid(post?.id, usertoken))
        }
    }, [post?.id, usertoken])
    const handleLike = async () => {
        if (usertoken) {
            setLike(true);
            const metadata = await getMetaData();

            const data = {
                "userData": {
                    fullName: firstName || '',
                    profilePic: null,
                    caption: null,
                },
                "date": new Date().toISOString(),
                "likedBy": localstore?.getUserId(),
                "metadata": metadata,
                "postId": post?.id
            }
            await axios.post(`${URL.publish}addlikes`, data, {
                headers: {
                    'Authorization': `Bearer ${usertoken}`
                }
            }).then(res => {
                dispatch(getpostbyid(post?.id, usertoken));
            })
        } else {
            setOpen(true)
            // alert('Your are unable to access the data! Please Login Or SignUp')
            // navigate(selectedbranch?.name ? `/${selectedbranch?.name}/login` : '/login')
        }

    }
    const handleUnlike = () => {
        setLike(false);
        axios
            .delete(`${URL.publish}deletelikesbyid/${post?.likedId}`, {
                headers: {
                    Authorization: `Bearer ${usertoken}`,
                },
            })
            .then(res => {
                dispatch(getpostbyid(post?.id, usertoken));
            })
            .catch(err => {
                console.log(err.message, 'handleUnlike');
            });
    }
    return (
        <Box sx={{ height: '76px' }}>

            <Box
                sx={{
                    display: "flex",
                    pl: 1,
                    pr: 1,
                    mt: 1,
                    justifyContent: "space-between",
                    height: "22px",
                }}
            >
                {item.likeCount > 0 ? (
                    <Typography
                        sx={{
                            fontSize: { xs: "7px", sm: "11px", md: "14px", lg: '14px' },
                            // margin: "5px",
                            height: '11px'
                        }}
                    >
                        {item.likeCount} {item.likeCount === 1 ? "person" : "people"}{" "}
                        {"liked"}
                    </Typography>
                ) : null}
                {item.shareCount > 0 ? (
                    <Typography
                        sx={{
                            fontSize: { xs: "7px", sm: "11px", md: "14px", lg: '14px' },

                        }}
                    >
                        {item.shareCount} {item.shareCount === 1 ? "person" : "people"}{" "}
                        Answered
                    </Typography>
                ) : null}
            </Box>

            {/* <Divider sx={{ mt: "13px" }} /> */}
            <Box >
                <Box sx={{ height: '15px' }}></Box>
                <Box
                    sx={{ display: "flex", justifyContent: "space-between", p: "2px 10px", }}
                >
                    <Box >
                        {item.liked || like === true ? (
                            <Box onClick={handleUnlike}>
                                <IconButton aria-label="unlike" >
                                    <img src={LikedIcon} alt="Liked" />
                                </IconButton>
                                <Typography variant="caption" sx={{ cursor: "pointer", fontSize: '14px' }}>
                                    Like
                                </Typography>
                            </Box>
                        ) : (
                            <Box onClick={handleLike}>
                                <IconButton aria-label="like" >
                                    <img
                                        src={likeIcon}
                                        width={18}
                                        height={18}
                                        alt="like"
                                    />
                                </IconButton>
                                <Typography variant="caption" sx={{ cursor: "pointer", fontSize: '14px' }}>
                                    Like
                                </Typography>
                            </Box>
                        )}

                    </Box>
                    <Box onClick={() => handleNavigatePost(item)}>
                        <IconButton aria-label="comment">
                            <img
                                src={CommentIcon}
                                width={18}
                                height={18}
                                alt="comment"
                            />
                        </IconButton>
                        <Typography variant="caption" sx={{ cursor: "pointer", fontSize: '14px' }}>
                            Ask Doctor
                        </Typography>
                    </Box>

                </Box>
            </Box>
        </Box >);
}

export default HealthcomActions;
